import { Redirect, Route, Switch } from 'wouter';
import {
  cacheExchange,
  Client,
  fetchExchange,
  mapExchange,
  Provider as UrqlProvider,
} from 'urql';
import { HelmetProvider } from 'react-helmet-async';

import { IntlProvider, load } from '@progress/kendo-react-intl';

import LikelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import CurrencyData from 'cldr-core/supplemental/currencyData.json';
import WeekData from 'cldr-core/supplemental/weekData.json';
import Numbers from 'cldr-numbers-full/main/de/numbers.json';
import Currencies from 'cldr-numbers-full/main/de/currencies.json';
import DateFields from 'cldr-dates-full/main/de/dateFields.json';
import CaGregorian from 'cldr-dates-full/main/de/ca-gregorian.json';
import TimeZoneNames from 'cldr-dates-full/main/de/timeZoneNames.json';

import { RedirectToLoginAndBack } from '../admin/RedirectToLoginAndBack.tsx';
import { AdminLayout } from '../admin/AdminLayout.tsx';
import { SeminarsPage } from '../admin/seminars/SeminarsPage.tsx';
import { GeneralSettingsPage } from '../admin/GeneralSettingsPage.tsx';
import { PublicSeminarPageFields } from '../admin/PublicSeminarPageFieldsPage.tsx';
import { CompanyFlagsPage } from '../admin/CompanyFlagsPage.tsx';
import { CompanyCategoriesPage } from '../admin/CompanyCategoriesPage.tsx';
import { MailSettingsPage } from '../admin/MailSettingsPage.tsx';
import { CompanySitesPage } from '../admin/company-sites/CompanySitesPage.tsx';
import { CompanySiteNewPage } from '../admin/company-sites/CompanySiteNewPage.tsx';
import { CompanyBannerPage } from '../admin/CompanyBannerPage.tsx';
import { CompanySiteEditPage } from '../admin/company-sites/CompanySiteEditPage.tsx';
import { UsersPage } from '../admin/users/UsersPage.tsx';
import { UserNewPage } from '../admin/users/UserNewPage.tsx';
import { UserEditPage } from '../admin/users/UserEditPage.tsx';
import { SeminarEditPage } from '../admin/seminars/SeminarEditPage.tsx';
import { PublicLayout } from '../public/PublicLayout.tsx';
import { PublicSeminar } from '../public/PublicSeminar.tsx';
import { SignUpFormPage } from '../admin/sign-up-form/SignUpFormPage.tsx';
import { SignUpFormElementNewPage } from '../admin/sign-up-form/SignUpFormElementNewPage.tsx';
import { SignUpFormElementEditPage } from '../admin/sign-up-form/SignUpFormElementEditPage.tsx';
import { PublicRegistration } from '../public/PublicRegistration.tsx';
import { PublicSignUpPage } from '../public/PublicSignUpPage.tsx';
import { SignUpFormSelectPage } from '../admin/sign-up-form-select/SignUpFormSelectPage.tsx';
import { PublicSeminarList } from '../public/PublicSeminarList.tsx';
import { PublicSite } from '../public/PublicSite.tsx';
import {
  PublicCompanyContext,
  PublicCompanyProvider,
} from '../public/contexts/PublicCompanyProvider.tsx';
import { UserCompaniesAndPrivilegeProvider } from '../admin/contexts/UserCompaniesAndPrivilegeProvider.tsx';
import { MySeminarList } from '../public/MySeminarList.tsx';
import { SignUpConfirmationTemplateEditor } from '../admin/mail-templates/SignUpConfirmationTemplateEditor.tsx';
import { ReminderSevenDaysTemplateEditor } from '../admin/mail-templates/ReminderSevenDaysTemplateEditor.tsx';
import { ReminderOneDayTemplateEditor } from '../admin/mail-templates/ReminderOneDayTemplateEditor.tsx';
import { MyProfileData } from '../public/MyProfileData.tsx';
import {
  PublicUserContextProvider,
  PublicUserInfoContext,
} from '../public/contexts/PublicUserInfoContext.tsx';
import { Loader } from '@progress/kendo-react-indicators';

load(
  LikelySubtags,
  CurrencyData,
  WeekData,
  Numbers,
  Currencies,
  DateFields,
  CaGregorian,
  TimeZoneNames,
);

// GraphQl client
const client = new Client({
  url: import.meta.env.VITE_GRAPHQL_ENDPOINT,
  exchanges: [
    cacheExchange,
    mapExchange({
      onError(error) {
        console.error(error);
      },
    }),
    fetchExchange,
  ],
  // Disable any caching for now. Can be optimized later
  requestPolicy: 'network-only',
});

export const App: React.FC = () => {
  return (
    <HelmetProvider>
      <UrqlProvider value={client}>
        <IntlProvider locale={'de'}>
          <PublicCompanyProvider>
            <PublicCompanyContext.Consumer>
              {([publicCompany]) =>
                publicCompany?.id ? (
                  <Switch>
                    <Route path="/admin" nest>
                      <Switch>
                        <Route path="/">
                          <RedirectToLoginAndBack target="seminars" />
                        </Route>
                        <UserCompaniesAndPrivilegeProvider>
                          <AdminLayout companyId={publicCompany?.id}>
                            <Route path="/seminars">
                              <SeminarsPage companyId={publicCompany?.id} />
                            </Route>
                            <Route path="/general">
                              <GeneralSettingsPage
                                companyId={publicCompany?.id}
                              />
                            </Route>
                            <Route path="/public-seminar-page-fields">
                              <PublicSeminarPageFields
                                companyId={publicCompany?.id}
                              />
                            </Route>
                            <Route path="/flags">
                              <CompanyFlagsPage companyId={publicCompany?.id} />
                            </Route>
                            <Route path="/sign-up-form-elements">
                              <SignUpFormPage companyId={publicCompany?.id} />
                            </Route>
                            <Route path="/sign-up-form-elements/new">
                              <SignUpFormElementNewPage
                                companyId={publicCompany?.id}
                              />
                            </Route>
                            <Route path="/sign-up-form-elements/edit/:id">
                              {(params) => (
                                <SignUpFormElementEditPage
                                  companyId={publicCompany?.id}
                                  signUpFormElementId={params.id}
                                />
                              )}
                            </Route>
                            <Route path="/sign-up-form-select">
                              <SignUpFormSelectPage
                                companyId={publicCompany?.id}
                              />
                            </Route>
                            <Route path="/categories">
                              <CompanyCategoriesPage
                                companyId={publicCompany?.id}
                              />
                            </Route>
                            <Route path="/mailsettings">
                              <MailSettingsPage companyId={publicCompany?.id} />
                            </Route>
                            <Route path="/mailsettings/templates/signup-confirmation">
                              <SignUpConfirmationTemplateEditor
                                companyId={publicCompany?.id}
                              />
                            </Route>
                            <Route path="/mailsettings/templates/reminder-seven-days">
                              <ReminderSevenDaysTemplateEditor
                                companyId={publicCompany?.id}
                              />
                            </Route>
                            <Route path="/mailsettings/templates/reminder-one-day">
                              <ReminderOneDayTemplateEditor
                                companyId={publicCompany?.id}
                              />
                            </Route>
                            <Route path="/banner">
                              <CompanyBannerPage
                                companyId={publicCompany?.id}
                              />
                            </Route>
                            <Route path="/sites">
                              <CompanySitesPage companyId={publicCompany?.id} />
                            </Route>
                            <Route path="/sites/new">
                              <CompanySiteNewPage
                                companyId={publicCompany?.id}
                              />
                            </Route>
                            <Route path="/sites/edit/:slug">
                              {(params) => (
                                <CompanySiteEditPage
                                  companyId={publicCompany?.id}
                                  slug={params.slug}
                                />
                              )}
                            </Route>
                            <Route path="/users">
                              <UsersPage companyId={publicCompany?.id} />
                            </Route>
                            <Route path="/users/new">
                              <UserNewPage companyId={publicCompany?.id} />
                            </Route>
                            <Route path="/users/edit/:userId">
                              {(params) => (
                                <UserEditPage
                                  companyId={publicCompany?.id}
                                  userId={params.userId}
                                />
                              )}
                            </Route>
                            <Route path="/">
                              <Redirect to="/seminars" />
                            </Route>
                            <Route path="/seminars/edit/:seminarId">
                              {(params) => (
                                <SeminarEditPage
                                  companyId={publicCompany?.id}
                                  seminarId={params.seminarId}
                                />
                              )}
                            </Route>
                          </AdminLayout>
                        </UserCompaniesAndPrivilegeProvider>
                      </Switch>
                    </Route>
                    <PublicUserContextProvider>
                      <PublicUserInfoContext.Consumer>
                        {({ userInfo }) => {
                          return (
                            <PublicLayout>
                              <Switch>
                                <Route path="/">
                                  {publicCompany.functionalSettings
                                    .passwordActive ? (
                                    !userInfo?.data && !userInfo?.error ? (
                                      <Loader
                                        type={'infinite-spinner'}
                                        className={
                                          'col-span-12 mt-8 justify-self-center !text-bts-theme-text-lighter'
                                        }
                                      />
                                    ) : userInfo?.data?.companiesForSessionUser
                                        ?.user ? (
                                      <PublicSeminarList
                                        companyId={publicCompany?.id}
                                      />
                                    ) : (
                                      <RedirectToLoginAndBack />
                                    )
                                  ) : (
                                    <PublicSeminarList
                                      companyId={publicCompany?.id}
                                    />
                                  )}
                                </Route>
                                <Route path="/register">
                                  <PublicRegistration
                                    companyId={publicCompany?.id}
                                  />
                                </Route>
                                <Route path="/category/:categoryId">
                                  {(params) => (
                                    <PublicSeminarList
                                      companyId={publicCompany?.id}
                                      categoryId={params.categoryId}
                                    />
                                  )}
                                </Route>
                                <Route path="/seminar/:seminarId" nest>
                                  {(params) => (
                                    <>
                                      <Route path="/">
                                        <PublicSeminar
                                          companyId={publicCompany?.id}
                                          seminarId={params.seminarId}
                                        />
                                      </Route>

                                      <Route path="/sign-up">
                                        <PublicSignUpPage
                                          companyId={publicCompany?.id}
                                          seminarId={params.seminarId}
                                        />
                                      </Route>
                                    </>
                                  )}
                                </Route>
                                <Route path="/site/:slug">
                                  {(params) => (
                                    <PublicSite
                                      companyId={publicCompany?.id}
                                      slug={params.slug}
                                    />
                                  )}
                                </Route>
                                <Route path="/profile/myseminars">
                                  <MySeminarList
                                    companyId={publicCompany?.id}
                                  />
                                </Route>
                                <Route path="/profile/data">
                                  <MyProfileData
                                    companyId={publicCompany?.id}
                                  />
                                </Route>
                              </Switch>
                            </PublicLayout>
                          );
                        }}
                      </PublicUserInfoContext.Consumer>
                    </PublicUserContextProvider>

                    <Route path="/error">
                      <h1>This page does not exist</h1>
                    </Route>
                  </Switch>
                ) : null
              }
            </PublicCompanyContext.Consumer>
          </PublicCompanyProvider>
        </IntlProvider>
      </UrqlProvider>
    </HelmetProvider>
  );
};
