import { Link, useLocation } from 'wouter';

interface PublicProfileSidebarProps {}

export const PublicProfileSidebar: React.FC<PublicProfileSidebarProps> = () => {
  const items = [
    { title: 'Meine Seminare', href: '/profile/myseminars' },
    { title: 'Stammdaten', href: '/profile/data' },
  ];

  const [location] = useLocation();

  return (
    <aside className="col-span-1 flex flex-col gap-4 md:col-span-3">
      <h2>Profil</h2>
      <ul className="flex flex-col divide-y rounded border border-gray-300 bg-white">
        {items.map((item, i) => {
          const isActive = location === item.href;
          return (
            <li
              key={item.href}
              className={`text-black ${i == 0 ? 'rounded-t' : ''} ${i == items.length - 1 ? 'rounded-b' : ''} ${isActive ? 'bg-bts-theme-primary' : 'hover:bg-gray-200'}`}
            >
              <Link
                href={item.href}
                className={
                  isActive
                    ? 'bg-bts-theme-primary text-white'
                    : '' + ' h-full w-full'
                }
              >
                <span className="flex h-full w-full items-center px-6 py-4">
                  {item.title}
                </span>
              </Link>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};
